import { defineComponent, ref, Ref } from '@vue/composition-api';

import Card from '@/components/Card';
import FetchData from '@/components/FetchData';

import SvgPlayAll from './svg-play-all';

import MusicSongList from '@/components/Article/MusicSongList';

import store from '@/store';

interface Author {
	onlyid: string | number;
	authorName: string;
}

interface BgmList {
	readonly onlyid: string | number;
	title: string;
	author: string | string[] | Author | Author[];
	album: string;
	duration: number;
}

interface BgmConfig {
	playmode: number;
	autoplay: boolean;
}
interface BgmTrait {
	config: BgmConfig;
	playlist: BgmList[];
}

export default defineComponent({
	components: { Card, FetchData, SvgPlayAll, MusicSongList },
	setup() {
		const bgmList: Ref<BgmList[]> = ref([
			{
				onlyid: 'd_7eb5f051d1f4585c9c073776d46113337d84e99f',
				title: '我们的歌',
				author: '丽江小倩',
				album: '我们的歌',
				duration: 244.01,
			},
		]);
		const bgmConfig: Ref<BgmConfig> = ref({
			playmode: 0,
			autoplay: false,
		});
		const praseDuration = (time: number) =>
			`${Math.floor(time / 60)
				.toString()
				.padStart(2, '0')}:${Math.floor(time % 60)
				.toString()
				.padStart(2, '0')}`;
		const touchData = (data: BgmTrait) => {
			//bgmList.value = data.playlist;
			//console.log(data);
			bgmList.value = data.playlist;
			bgmConfig.value = data.config;
		};
		const playAll = () => {
			//alert('执行全部播放');
			playTrack(0);
		};
		const playTrack = (track: number) => {
			store.commit('musicPlayerControl', `${Math.random()}#replaceListAndPlay#${JSON.stringify({ list: [...bgmList.value], playOnlyid: bgmList?.value[track]?.onlyid || '' })}`);
		};

		return { bgmList, bgmConfig, touchData, praseDuration, playAll, playTrack };
	},
});
